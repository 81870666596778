import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"align":"center"}},[_c(VSheet,{attrs:{"rounded":"lg","max-width":"900px"}},[(!_vm.currentUser)?_c(VSkeletonLoader,{attrs:{"loading":"","type":"list-item-avatar, divider, list-item, list-item"}}):_c(VCard,[_c(VCardTitle,[_c(VAvatar,[_c(VImg,{attrs:{"src":_vm.currentUser.picture}})],1),_c('p',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")])],1),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,[_c('h3',[_vm._v("Preferences")])])],1),_c(VRow,[_c(VCol,[_c(VCheckbox,{attrs:{"label":"Receive meeting emails"},model:{value:(_vm.currentUser.preferences.meeting_emails),callback:function ($$v) {_vm.$set(_vm.currentUser.preferences, "meeting_emails", $$v)},expression:"currentUser.preferences.meeting_emails"}})],1),_c(VCol,[_c(VCheckbox,{attrs:{"label":"Receive meeting minutes emails"},model:{value:(_vm.currentUser.preferences.minutes_emails),callback:function ($$v) {_vm.$set(_vm.currentUser.preferences, "minutes_emails", $$v)},expression:"currentUser.preferences.minutes_emails"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"align":"center"}},[_c('text-button',{attrs:{"disabled":_vm.userLoading,"loading":_vm.userLoading},on:{"click":_vm.updatePreferences}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }