
import { User } from "@auth0/auth0-spa-js";

import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { UserDTO } from "~/types/User";

import TextButton from "../components/TextButton.vue";

@Component({
  components: {
    TextButton,
  },
})
export default class UsersView extends Vue {
  @Prop()
  title!: string;

  @Action("GET_CURRENT_USER")
  getCurrentUser!: () => Promise<User>;

  @Action("UPDATE_CURRENT_USER")
  updateUser!: (user: Partial<UserDTO>) => Promise<void>;

  @State
  userLoading!: boolean;

  currentUser: User | null = null;

  updatePreferences(): void {
    if (this.currentUser) {
      this.updateUser(this.currentUser);
    }
  }

  async created(): Promise<void> {
    this.currentUser = await this.getCurrentUser();
  }
}
