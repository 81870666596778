
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, State } from "vuex-class";
import { User as Auth0User } from "@auth0/auth0-spa-js";

import type { AppUser, UserList } from "~/types/User";
import type { User } from "~/data/User";
import { RoleDTO, RolesMap, SetUserRolesParam } from "~/types/Role";

@Component({})
export default class UsersView extends Vue {
  users: AppUser[] = [];
  headers = [
    { text: "Name", value: "name" },
    { text: "Email", value: "email" },
    { text: "Roles", value: "roles" },
    { text: "Meeting Emails", value: "preferences.meeting_emails" },
    { text: "Minutes Emails", value: "preferences.minutes_emails" },
    { text: "Actions", value: "actions", sortable: false },
  ];
  roles: RolesMap = {};
  page = 1;
  totalPages = 1;
  pageSize = 10;
  loading = false;

  defaultUser: AppUser = {
    email: "",
    id: "",
    name: "",
    roles: [],
    preferences: {
      meeting_emails: false,
      minutes_emails: false,
    },
  };

  editingUser: AppUser = { ...this.defaultUser };

  dialogGroups = false;
  dialogDelete = false;

  rolesUpdating = false;

  @Action("GET_USER_LIST")
  getUserList!: (opts: unknown) => Promise<UserList>;

  @Action("GET_ROLE_LIST")
  getRoleList!: () => Promise<RolesMap>;

  @Action("SET_USER_ROLES")
  setUserRoles!: (param: SetUserRolesParam) => unknown;

  @Action("DELETE_USER")
  deleteUser!: (userId: string) => Promise<unknown>;

  @State("userLoading")
  isUserListLoading!: boolean;

  @State("roleListLoading")
  isRoleListLoading!: boolean;

  @Getter
  currentUser!: Auth0User;

  @State("userDeleting")
  isUserDeleting!: boolean;

  getRole(id: string): RoleDTO {
    return this.roles?.[id] ?? {};
  }

  changePage(newPage: number): void {
    this.loadList(newPage);
  }

  editItem(item: User): void {
    this.editingUser = item;
    this.dialogGroups = true;
  }

  closeEditItem(): void {
    this.editingUser = { ...this.defaultUser };
    this.dialogGroups = false;
  }

  async saveItem(): Promise<void> {
    try {
      this.rolesUpdating = true;
      await this.setUserRoles({
        userId: this.editingUser.id,
        roles: this.editingUser.roles,
      });
      this.closeEditItem();
    } catch (err) {
      // TODO Show error message
    } finally {
      this.rolesUpdating = false;
    }
  }

  deleteItem(item: User): void {
    this.editingUser = item;
    this.dialogDelete = true;
  }

  async deleteConfirm(): Promise<void> {
    await this.deleteUser(this.editingUser.id);
    this.users = this.users.filter((u) => u.id !== this.editingUser.id);
    this.closeDelete();
  }

  closeDelete(): void {
    this.editingUser = { ...this.defaultUser };
    this.dialogDelete = false;
  }

  async loadList(fetchPage = 1): Promise<void> {
    const skip = this.pageSize * (fetchPage - 1);
    this.loading = true;
    try {
      const [roles, { users, page, total }] = await Promise.all([
        this.getRoleList(),
        this.getUserList({
          limit: this.pageSize,
          skip,
        }),
      ]);

      this.roles = roles;
      this.users = users as User[];
      this.page = page;
      this.totalPages = Math.ceil(total / this.pageSize) || 1;
    } finally {
      this.loading = false;
    }
  }

  async created(): Promise<void> {
    await this.loadList();
  }
}
